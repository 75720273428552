import { NextApiRequest } from 'next'
import Image from 'next/image'

import { Button, Center } from '../components'
import { ROUTE_ROOT } from '../lib/constants'
import { supabaseClient, userRequestGuard } from '../lib/supabase'

export default function Login() {
  async function signInWithFacebook() {
    await supabaseClient.auth.signIn(
      {
        provider: 'facebook',
      },
      {
        scopes: 'public_profile',
        redirectTo: `${process.env.NEXT_PUBLIC_APP_BASE_URL}/welcome`,
      },
    )
  }
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Center>
          <Image src="/images/workflow-mark-pink-600.svg" height="48" width="48" alt="Logo" />
        </Center>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <Button className="mt-1 w-full" onClick={signInWithFacebook}>
            <span className="sr-only">Sign in with Facebook</span>
            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                clipRule="evenodd"
              />
            </svg>
            &nbsp; Connect with Facebook
          </Button>
        </div>
      </div>
    </div>
  )
}

export async function getServerSideProps({ req }: { req: NextApiRequest }) {
  // If there's already a logged-in user, redirect to the root
  const user = await userRequestGuard(req)
  if (user) return { redirect: { destination: ROUTE_ROOT, permanent: false } }

  return { props: {} }
}
